const id = {
  menuSMS: "SMS",
  templateSMS: "Template SMS",
  templateSMSDescription:
    "Add and set up sms templates that can be sent to customers.",
  menuBroadcastSms: "Broadcast",
  templateSms: "Broadcast SMS",
  tabTitleSmsBroadcast: "SMS",
  tabTitleSmsHistory: "Riwayat",
  pickerDescriptionSmsBroadcastAndTemplate: "Broadcast dan Template",
};

const en = {
  menuSMS: "SMS",
  templateSMS: "SMS Template",
  templateSMSDescription:
    "Add and set up sms templates that can be sent to customers.",
  menuBroadcastSms: "Broadcast",
  templateSms: "SMS Broadcast",
  tabTitleSmsBroadcast: "SMS",
  tabTitleSmsHistory: "History",
  pickerDescriptionSmsBroadcastAndTemplate: "Broadcast and Template",
};

export default { id, en };
