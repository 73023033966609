import store from "@/store";
import Vue from "vue";
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from "vue-router";
import { KoalaPlusChatCreditServiceClient } from "@/serviceClients/KoalaPlusChatCreditServiceClient";
import defineAbilityFor from "@/brand/current/ability";
import { Ability } from "@casl/ability";
import vuetify from "../plugins/vuetify";
import { brandConstants } from "@/brand/current/PartnerConstants";
import { getUserDetailFromJwt } from "@/utils/jwtUtil";
import { RefreshableRequestHelper } from "@/helpers/RefreshableRequestHelper";
import { KoalaPlusInboundServiceClient } from "@/serviceClients/KoalaPlusInboundServiceClient";
Vue.use(VueRouter);

const { i18n } = vuetify;
const routes: Array<RouteConfig> = [
  // {
  //   path: "/",
  //   name: "home",
  //   component: HomeView,
  // },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/LoginView.vue"),
  },
  {
    path: "/register",
    name: "Register",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/RegisterPageView.vue"),
  },
  {
    path: "/",
    name: "main",
    component: () =>
      import(/* webpackChunkName: "main-layout" */ "../views/MainLayout.vue"),
    children: [
      {
        path: "dashboard",
        name: "dashboard-page",
        component: () =>
          import(/* webpackChunkName: "home-view" */ "../views/HomeView.vue"),
        meta: {
          headerTitle: "Dashboard",
          requiresAuth: true,
          premium: false,
          permission: "DASHBOARD",
        },
      },
      {
        path: "/category",
        name: "category",
        component: () =>
          import(
            /* webpackChunkName: "product" */ "../views/CategoryPageView.vue"
          ),
        meta: {
          headerTitle: "Product",
          requiresAuth: true,
          premium: true,
          permission: "CATEGORY",
        },
      },
      {
        path: "/broadcast",
        name: "broadcast",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "reset-main" */ "../views/CommonRouterView.vue"
          ),
        children: [
          { path: "", redirect: "/broadcast/whatsapp" },
          {
            path: "whatsapp",
            name: "broadcast-whatsapp",
            component: () =>
              import(
                /* webpackChunkName: "reset-password-view-phone" */ "../views/SendWhatsAppViberPageView.vue"
              ),
            meta: {
              headerTitle: "Broadcast",
              requiresAuth: true,
              premium: true,
              permission: "WHATSAPP_BROADCAST",
              provider: "whatsapp",
            },
          },
          {
            path: "viber",
            name: "broadcast-viber",
            component: () =>
              import(
                /* webpackChunkName: "reset-password-view-phone" */ "../views/SendWhatsAppViberPageView.vue"
              ),
            meta: {
              headerTitle: "Broadcast",
              requiresAuth: true,
              premium: true,
              permission: "VIBER_BROADCAST",
              provider: "viber",
            },
          },
          {
            path: "email",
            name: "broadcast-email",
            component: () =>
              import(
                /* webpackChunkName: "reset-password-view-phone" */ "../views/SendEmailPageView.vue"
              ),
            meta: {
              headerTitle: "Broadcast",
              requiresAuth: true,
              premium: true,
              permission: "EMAIL_BROADCAST",
              provider: "email",
            },
          },
          {
            path: "sms",
            name: "broadcast-sms",
            component: () =>
              import(
                /* webpackChunkName: "reset-password-view-phone" */ "../views/SmsBroadcastPageView.vue"
              ),
            meta: {
              headerTitle: "Broadcast",
              requiresAuth: true,
              premium: true,
              permission: "SMS_BROADCAST_TEMPLATE",
            },
          },
          {
            path: "sms/detail",
            name: "broadcast-sms-detail",
            component: () =>
              import(
                /* webpackChunkName: "broadcast-sms-detail" */ "../views/BroadcastDetailPageView.vue"
              ),
            meta: {
              headerTitle: "Broadcast",
              requiresAuth: true,
              premium: true,
              permission: "SMS_BROADCAST_TEMPLATE",
            },
          },
          {
            path: "detail",
            name: "broadcast-detail",
            component: () =>
              import(
                /* webpackChunkName: "broadcast-detail" */ "../views/BroadcastDetailPageView.vue"
              ),
            meta: {
              headerTitle: "Broadcast",
              requiresAuth: true,
              premium: true,
              permission: "EMAIL_BROADCAST",
            },
          },
          {
            path: "voice/detail",
            name: "broadcast-voice-detail",
            component: () =>
              import(
                /* webpackChunkName: "broadcast-detail" */ "../views/BroadcastDetailPageView.vue"
              ),
            meta: {
              headerTitle: "Broadcast",
              requiresAuth: true,
              premium: true,
              permission: "VOICE_BROADCAST",
            },
          },
          {
            path: "voice",
            name: "broadcast-voice",
            component: () =>
              import(
                /* webpackChunkName: "inbox" */ "../views/VoiceBroadcastPageView.vue"
              ),
            meta: {
              headerTitle: "Voice Broadcast",
              requiresAuth: true,
              premium: true,
              permission: "VOICE_BROADCAST",
            },
          },
        ],
      },
      {
        path: "/advanced-broadcast/create/broadcast",
        name: "createAdvancedBroadcast",
        component: () =>
          import(
            /* webpackChunkName: "createAdvancedBroadcast" */ "../views/CreateAdvancedBroadcastPageView.vue"
          ),
        meta: {
          headerTitle: "Create Advanced Broadcast",
          requiresAuth: true,
          premium: true,
          permission: "ADVANCED_BROADCAST",
        },
      },
      {
        path: "/outbound-call/call-center",
        name: "call-center",
        component: () =>
          import(
            /* webpackChunkName: "inbox" */ "../views/customerService/CallCenterPageView.vue"
          ),
        meta: {
          headerTitle: "Call Center",
          requiresAuth: true,
          premium: true,
          permission: "CALL_CENTER",
        },
      },
      {
        path: "/outbound-call/call-center-history",
        name: "call-center-history",
        component: () =>
          import(
            /* webpackChunkName: "inbox" */ "../views/customerService/CallCenterHistoryPageView.vue"
          ),
        meta: {
          headerTitle: "Call Center History",
          requiresAuth: true,
          premium: true,
          permission: "CALL_CENTER_HISTORY",
        },
      },
      {
        path: "/inbound-call",
        name: "inbound-call-page",
        component: () =>
          import(
            /* webpackChunkName: "inboundCallPage" */ "../views/InboundCallPageView.vue"
          ),
        meta: {
          headerTitle: "Inbound Call",
          requiresAuth: true,
          premium: true,
          permission: "INBOUND_CALL",
        },
      },
      {
        path: "/inbound-call-history",
        name: "inbound-call-history-page",
        component: () =>
          import(
            /* webpackChunkName: "InboundCallHistory" */ "../views/InboundCallHistoryPageView.vue"
          ),
        meta: {
          headerTitle: "Inbound Call History",
          requiresAuth: true,
          premium: true,
          permission: "INBOUND_CALL",
        },
      },
      {
        path: "/customer-service/inbox",
        name: "inbox",
        component: () =>
          import(
            /* webpackChunkName: "inbox" */ "../views/CustomerServiceInboxPageView.vue"
          ),
        meta: {
          headerTitle: "Inbox",
          requiresAuth: true,
          premium: true,
          permission: "CUSTOMER_SERVICE_INBOX",
        },
        beforeEnter: (to, from, next) => {
          if (store.getters.getIsActivePlayMode) {
            return next({ name: "inbox-reference-id" });
          } else {
            const user = getUserDetailFromJwt(
              store.getters.getKokattoTokenAccess
            );

            const ability = new Ability(
              defineAbilityFor({
                role: store.getters.getAccountRole,
                accountCountry: store.getters.getAccountCountry,
                featuresAccessPermissions: user.featuresAccessPermission,
                featuresPermissions: user.featuresPermission,
              })
            );
            const playModeState =
              store.getters["CustomerServicePlayMode/getPlayModeState"];
            if (playModeState?.data?.isActivePlayMode) {
              return next({ name: "inbox-reference-id" });
            } else if (ability.can("READ", "VIEW_FEATURE"))
              return next({ name: "ticket-view" });
            else return next();
          }
        },
      },
      {
        path: "/customer-service/inbox/new",
        name: "inbox-new",
        component: () =>
          import(
            /* webpackChunkName: "inbox" */ "../components/customerService/TicketView/TicketListComponent/TicketListComponent.vue"
          ),
        meta: {
          headerTitle: "Inbox",
          requiresAuth: true,
          premium: true,
          permission: "CUSTOMER_SERVICE_INBOX",
        },
      },
      // Temporary route. Might be deleted later
      {
        path: "/customer-service/ticket-view",
        name: "ticket-view",
        component: () =>
          import(
            /* webpackChunkName: "ticket-view" */ "../views/TicketPageView.vue"
          ),
        meta: {
          headerTitle: "Tickets",
          requiresAuth: true,
          premium: true,
          permission: "VIEW_FEATURE",
        },
      },
      {
        path: "/customer-service/inbox-chatroom",
        name: "inbox-reference-id",
        component: () =>
          import(
            /* webpackChunkName: "inbox" */ "../views/CustomerServiceInboxReferenceIdPageView.vue"
          ),
        meta: {
          headerTitle: "Inbox",
          requiresAuth: true,
          premium: true,
          permission: "CUSTOMER_SERVICE_INBOX",
        },
        async beforeEnter(to, from, next) {
          const chatRoomId = to.query.referenceId;
          const selectedChatroom = to.params.chatRoom;
          let playModeState =
            store.getters["CustomerServicePlayMode/getPlayModeState"];
          const referrer = from.name || "inbox-reference-id";
          if (playModeState?.data?.isActivePlayMode) {
            await store.dispatch(
              "CustomerServicePlayMode/checkCsCurrentTicket"
            );
            playModeState =
              store.getters["CustomerServicePlayMode/getPlayModeState"];
            const currentChatRooom =
              playModeState?.data?.currentTicketRoom || null;
            if (currentChatRooom?.id == chatRoomId && selectedChatroom) {
              next();
            } else if (currentChatRooom) {
              next({
                name: "inbox-reference-id",
                query: {
                  referenceId: currentChatRooom.id,
                  referrer: referrer,
                },
                params: {
                  chatRoom: currentChatRooom,
                },
              });
            } else {
              next();
            }
          } else if (!chatRoomId || !selectedChatroom) {
            next({ name: "inbox" });
          }
        },
      },
      {
        path: "/customer-service/chat-history",
        name: "chat-history",
        component: () =>
          import(
            /* webpackChunkName: "chat-history" */ "../views/customerService/ChatHistoryPageView.vue"
          ),
        meta: {
          headerTitle: "Chat History",
          requiresAuth: true,
          premium: true,
          permission: "CUSTOMER_SERVICE_HISTORY",
        },
        beforeEnter: (to, from, next) => {
          const user = getUserDetailFromJwt(
            store.getters.getKokattoTokenAccess
          );

          const ability = new Ability(
            defineAbilityFor({
              role: store.getters.getAccountRole,
              accountCountry: store.getters.getAccountCountry,
              featuresAccessPermissions: user.featuresAccessPermission,
              featuresPermissions: user.featuresPermission,
            })
          );

          if (ability.can("READ", "ADVANCE_SEARCH"))
            return next({ name: "ticket-chat-list" });
          else return next();
        },
      },
      {
        path: "/customer-service/ticket-list",
        name: "ticket-chat-list",
        component: () =>
          import(
            /* webpackChunkName: "ticket-chat-list" */ "../views/TicketChatListPageView.vue"
          ),
        meta: {
          headerTitle: "Ticket List",
          requiresAuth: true,
          premium: true,
          permission: "ADVANCE_SEARCH",
        },
      },
      {
        path: "/customer-service/chat-setting",
        name: "chat-setting",
        component: () =>
          import(
            /* webpackChunkName: "wallet" */ "../views/customerService/ChatSettingPageView.vue"
          ),
        meta: {
          headerTitle: "Chat Setting",
          requiresAuth: true,
          premium: true,
          permission: "CUSTOMER_SERVICE_SETTING",
        },
      },
      {
        path: "/template",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "template" */ "../views/CommonRouterView.vue"
          ),
        children: [
          {
            path: "",
            redirect: { name: "template-whatsapp" },
          },
          {
            path: "whatsapp",
            name: "template-whatsapp",
            component: () =>
              import(
                /* webpackChunkName: "whatsapp-template-list" */ "../views/WhatsAppViberTemplatePageView.vue"
              ),
            meta: {
              headerTitle: "Template",
              requiresAuth: true,
              premium: true,
              permission: "WHATSAPP_TEMPLATE",
            },
          },
          {
            path: "viber",
            name: "template-viber",
            component: () =>
              import(
                /* webpackChunkName: "viber-template-list" */ "../views/WhatsAppViberTemplatePageView.vue"
              ),
            meta: {
              headerTitle: "Template",
              requiresAuth: true,
              premium: true,
              permission: "VIBER_TEMPLATE",
            },
          },
          {
            path: "email",
            name: "template-email",
            component: () =>
              import(
                /* webpackChunkName: "email-template-list" */ "../views/EmailTemplatePageView.vue"
              ),
            meta: {
              headerTitle: "Template",
              requiresAuth: true,
              premium: true,
              permission: "EMAIL_TEMPLATE",
            },
          },
          {
            path: "sms",
            name: "template-sms",
            component: () =>
              import(
                /* webpackChunkName: "sms-template-list" */ "../views/SMSTemplatePageView.vue"
              ),
            meta: {
              headerTitle: "Template",
              requiresAuth: true,
              premium: true,
              permission: "SMS_BROADCAST_TEMPLATE",
            },
          },
        ],
      },
      {
        path: "/template/create",
        component: () =>
          import(
            /* webpackChunkName: "createTemplate" */ "../views/CommonRouterView.vue"
          ),
        children: [
          { path: "", redirect: { name: "createTemplateWhatsApp" } },
          {
            path: "whatsapp",
            name: "createTemplateWhatsApp",
            component: () =>
              import(
                /* webpackChunkName: "reset-password-view-phone" */ "../views/CreateTemplatePageView.vue"
              ),
            meta: {
              headerTitle: "Create Template Whatsapp",
              requiresAuth: true,
              premium: true,
              permission: "WHATSAPP_TEMPLATE",
            },
          },
          {
            path: "viber",
            name: "createTemplateViber",
            component: () =>
              import(
                /* webpackChunkName: "reset-password-view-phone" */ "../views/CreateTemplatePageView.vue"
              ),
            meta: {
              headerTitle: "Create Template Viber",
              requiresAuth: true,
              premium: true,
              permission: "VIBER_TEMPLATE",
            },
          },
          {
            path: "email",
            name: "createTemplateEmail",
            component: () =>
              import(
                /* webpackChunkName: "reset-password-view-phone" */ "../views/CreateTemplateEmailPageView.vue"
              ),
            meta: {
              headerTitle: "Create Template Email",
              requiresAuth: true,
              premium: true,
              permission: "EMAIL_TEMPLATE",
            },
          },
          {
            path: "sms",
            name: "createTemplateSMS",
            component: () =>
              import(
                /* webpackChunkName: "reset-password-view-phone" */ "../views/CreateTemplateSMSPageView.vue"
              ),
            meta: {
              headerTitle: "Create Template SMS",
              requiresAuth: true,
              premium: true,
              permission: "SMS_BROADCAST_TEMPLATE",
            },
          },
        ],
      },
      {
        path: "/template/edit",
        name: "editTemplate",
        component: () =>
          import(
            /* webpackChunkName: "createTemplate" */ "../views/CreateTemplatePageView.vue"
          ),
        props: true,
        beforeEnter: (to, from, next) => {
          if (!to.params.template) return next("/template");
          return next();
        },
        children: [
          {
            path: "whatsapp",
            name: "editTemplateWhatsApp",
            component: () =>
              import(
                /* webpackChunkName: "reset-password-view-phone" */ "../views/CreateTemplatePageView.vue"
              ),
            meta: {
              headerTitle: "Edit Template",
              requiresAuth: true,
              premium: true,
              permission: "WHATSAPP_TEMPLATE",
            },
          },
          {
            path: "viber",
            name: "editTemplateViber",
            component: () =>
              import(
                /* webpackChunkName: "reset-password-view-phone" */ "../views/CreateTemplatePageView.vue"
              ),
            meta: {
              headerTitle: "Edit Viber",
              requiresAuth: true,
              premium: true,
              permission: "VIBER_TEMPLATE",
            },
          },
        ],
      },
      {
        path: "/follow-up/create/whatsapp",
        name: "createFollowUp",
        component: () =>
          import(
            /* webpackChunkName: "createFollowUp" */ "../views/CreateFollowUpPageView.vue"
          ),
        meta: {
          headerTitle: "Create Follow Up",
          requiresAuth: true,
          premium: true,
          permission: "WHATSAPP_TEMPLATE",
        },
      },
      {
        path: "/follow-up/edit/whatsapp",
        name: "editFollowUp",
        component: () =>
          import(
            /* webpackChunkName: "createFollowUp" */ "../views/CreateFollowUpPageView.vue"
          ),
        props: true,
        beforeEnter: (to, from, next) => {
          if (!to.params.template) return next("/template");
          return next();
        },
        meta: {
          headerTitle: "Edit Template",
          requiresAuth: true,
          premium: true,
          permission: "WHATSAPP_TEMPLATE",
        },
      },
      {
        path: "/shopify",
        name: "shopify",
        component: () =>
          import(
            /* webpackChunkName: "shopify" */ "../views/ShopifyPageView.vue"
          ),
        meta: {
          headerTitle: "Shopify",
          requiresAuth: true,
          premium: true,
          permission: "SHOPIFY",
        },
      },
      {
        path: "/chatbot",
        name: "chatbot",
        component: () =>
          import(
            /* webpackChunkName: "template" */ "../views/ChatbotPageView.vue"
          ),
        meta: {
          headerTitle: "Chatbot",
          requiresAuth: true,
          premium: true,
          permission: "WHATSAPP_CHATBOT",
        },
      },
      {
        path: "/chat-credits",
        name: "chatCredits",
        component: () =>
          import(
            /* webpackChunkName: "template" */ "../views/ChatCreditsPageView.vue"
          ),
        meta: {
          headerTitle: "ChatCredits",
          requiresAuth: true,
          premium: true,
          permission: "CHAT_CREDITS",
        },
      },
      {
        path: "/cs-performance",
        name: "CSPerformance",
        component: () =>
          import(
            /* webpackChunkName: "template" */ "../views/AgentCSPerformancePageView.vue"
          ),
      },
      {
        path: "/wallet",
        name: "wallet",
        component: () =>
          import(
            /* webpackChunkName: "wallet" */ "../views/WalletPageView.vue"
          ),
        meta: {
          headerTitle: "Wallet",
          requiresAuth: true,
          premium: true,
          permission: "WALLET",
        },
      },
      {
        path: "/setting/admin",
        name: "setting-admin",
        component: () =>
          import(
            /* webpackChunkName: "wallet" */ "../views/SettingAdminPageView.vue"
          ),
        meta: {
          headerTitle: "Admin Setting",
          requiresAuth: false,
          premium: false,
          permission: "SETTING_ADMIN",
        },
      },
      {
        path: "/product/list",
        name: "product",
        component: () =>
          import(
            /* webpackChunkName: "template" */ "../views/ProductPageView.vue"
          ),
        meta: {
          headerTitle: "Product",
          requiresAuth: true,
          premium: false,
          permission: "PRODUCT",
        },
      },
      {
        path: "/product/whatsapp-catalogue",
        name: "whatsapp-catalogue",
        component: () =>
          import(
            /* webpackChunkName: "template" */ "../views/WhatsappCataloguetPageView.vue"
          ),
        meta: {
          headerTitle: "Whatsapp Catalogue",
          requiresAuth: true,
          premium: false,
          permission: "WHATSAPP_CATALOGUE",
        },
      },
      {
        path: "/product/add-product",
        name: "add-product",
        component: () =>
          import(
            /* webpackChunkName: "template" */ "../views/AddProductPageView.vue"
          ),
        meta: {
          headerTitle: "AddProduct",
          requiresAuth: true,
          premium: false,
          permission: "CREATE_PRODUCT",
        },
      },
      {
        path: "/setting/store",
        name: "setting-store",
        component: () =>
          import(
            /* webpackChunkName: "wallet" */ "../views/SettingStorePageView.vue"
          ),
        meta: {
          headerTitle: "Store Setting",
          requiresAuth: false,
          premium: false,
          permission: "SETTING_STORE",
        },
      },
      {
        path: "/setting/plan-and-pricing",
        name: "setting-plan-and-pricing",
        component: () =>
          import(
            /* webpackChunkName: "wallet" */ "../views/SettingPlanAndPricingPageView.vue"
          ),
        meta: {
          headerTitle: "Plan and Pricing Setting",
          requiresAuth: true,
          premium: false,
          permission: "SETTING_PLAN_PRICING",
        },
      },
      {
        path: "/transaction",
        name: "transaction",
        component: () =>
          import(
            /* webpackChunkName: "wallet" */ "../views/TransactionPageView.vue"
          ),
        meta: {
          headerTitle: "Transaction",
          requiresAuth: true,
          premium: true,
          permission: "TRANSACTION",
        },
      },
      {
        path: "/contact",
        name: "contact",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "wallet" */ "../views/ContactPageView.vue"
          ),
        meta: {
          headerTitle: "Contact",
          requiresAuth: true,
          premium: true,
          permission: "CONTACT",
        },
      },
      {
        path: "/contact/bulk-upload",
        name: "bulk-upload",
        component: () =>
          import(
            /* webpackChunkName: "template" */ "../views/AddBulkUploadContactPageView.vue"
          ),
        meta: {
          headerTitle: "Bulk Upload Contact",
          requiresAuth: true,
          premium: true,
          permission: "CONTACT",
        },
      },
      {
        path: "/contact/detail",
        name: "contact-detail",
        component: () =>
          import(
            /* webpackChunkName: "wallet" */ "../views/ContactDetailPageView.vue"
          ),
        meta: {
          headerTitle: "Contact Detail",
          requiresAuth: true,
          premium: true,
          permission: "CONTACT",
        },
      },
      {
        path: "/contact/add-contact-tag",
        name: "add-contact-tag",
        component: () =>
          import(
            /* webpackChunkName: "template" */ "../views/AddContactTagPageView.vue"
          ),
        meta: {
          headerTitle: "Add Contact Tag",
          requiresAuth: true,
          premium: true,
          permission: "CONTACT",
        },
      },
      {
        path: "/contact/edit-contact-tag/:tag",
        name: "edit-contact-tag",
        component: () =>
          import(
            /* webpackChunkName: "template" */ "../views/EditContactTagPageView.vue"
          ),
        meta: {
          headerTitle: "Edit Contact",
          requiresAuth: true,
          premium: true,
          permission: "CONTACT",
        },
      },
      {
        path: "/contact/custom-field",
        name: "custom-field",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "wallet" */ "../views/CustomFieldPageView.vue"
          ),
        meta: {
          headerTitle: "Custom Field",
          requiresAuth: true,
          premium: true,
          permission: "CONTACT",
        },
      },
      {
        path: "/social/publish",
        name: "social-publish",
        component: () =>
          import(
            /* webpackChunkName: "social-publish" */ "../views/SocialPublishPageView.vue"
          ),
        meta: {
          headerTitle: "Publish",
          requiresAuth: true,
          premium: true,
          permission: "SOCIAL_PUBLISH",
        },
      },
      {
        path: "/social/comments",
        name: "social-comments",
        component: () =>
          import(
            /* webpackChunkName: "social-comments" */ "../views/SocialCommentsPageView.vue"
          ),
        meta: {
          headerTitle: "Comments",
          requiresAuth: true,
          premium: true,
          permission: "SOCIAL_COMMENT",
        },
      },
      {
        path: "/livechat/create/",
        name: "createLiveChat",
        component: () =>
          import(
            /* webpackChunkName: "createLiveChat" */ "../views/LiveChatCreatePageView.vue"
          ),
        meta: {
          headerTitle: "Create Live Chat",
          requiresAuth: true,
          premium: true,
          permission: "CREATE_LIVE_CHAT",
        },
      },
      {
        path: "/livechat/edit/:id",
        name: "editLiveChat",
        component: () =>
          import(
            /* webpackChunkName: "editLiveChat" */ "../views/LiveChatEditPageView.vue"
          ),
        meta: {
          headerTitle: "Edit Live Chat",
          requiresAuth: true,
          premium: true,
          permission: "EDIT_LIVE_CHAT",
        },
      },
      {
        path: "/email/create",
        name: "createEmail",
        component: () =>
          import(
            /* webpackChunkName: "createEmail" */ "../views/EmailCreatePageView.vue"
          ),
        meta: {
          headerTitle: "Email",
          requiresAuth: true,
          premium: true,
          permission: "CREATE_EMAIL",
        },
      },
      {
        path: "/email/edit",
        name: "editEmail",
        component: () =>
          import(
            /* webpackChunkName: "editEmail" */ "../views/EmailConfigurationPageView.vue"
          ),
        meta: {
          headerTitle: "Email",
          requiresAuth: true,
          premium: true,
          permission: "EDIT_EMAIL",
        },
      },
      {
        path: "/setting/account",
        name: "setting-account",
        component: () =>
          import(
            /* webpackChunkName: "settingAccount" */ "../views/SettingAccountPageView.vue"
          ),
        meta: {
          headerTitle: "Account Setting",
          requiresAuth: true,
          premium: false,
          permission: "SETTING_ACCOUNT",
        },
      },
      {
        path: "/setting/self-change-password",
        name: "self-change-password",
        component: () =>
          import(
            /* webpackChunkName: "settingAccountSelfChangePassword" */ "../views/SettingAccountSelfChangePassword.vue"
          ),
        meta: {
          headerTitle: "Account Self Change Password Setting",
          requiresAuth: true,
          premium: false,
          permission: "SETTING_ACCOUNT",
        },
      },
      {
        path: "/setting/finance",
        name: "finance-page",
        component: () =>
          import(
            /* webpackChunkName: "ClientFinancePageView" */ "../views/ClientFinancePageView.vue"
          ),
        meta: {
          headerTitle: "Finance",
          requiresAuth: true,
          premium: false,
          permission: "SETTING_FINANCE",
        },
      },
      {
        path: "/email/template/preview",
        name: "emailTemplatePreview",
        component: () =>
          import(
            /* webpackChunkName: "emailTemplatePreview" */ "../views/EmailTemplatePreviewPageView.vue"
          ),
        meta: {
          headerTitle: "Editor Template Preview",
          requiresAuth: true,
          premium: true,
          permission: "EMAIL_TEMPLATE",
        },
      },
      {
        path: "/sms/template/preview",
        name: "smsTemplatePreview",
        component: () =>
          import(
            /* webpackChunkName: "smsTemplatePreview" */ "../views/SMSTemplatePreviewPageView.vue"
          ),
        meta: {
          headerTitle: "Editor Template Preview",
          requiresAuth: true,
          premium: true,
          permission: "SMS_BROADCAST_TEMPLATE",
        },
      },
      {
        path: "/setting/two-factor-verification-setup",
        name: "twoFactorVerificationSetup",
        component: () =>
          import(
            /* webpackChunkName: "emailTemplatePreview" */ "../views/MFASetupPageView.vue"
          ),
        meta: {
          headerTitle: "Two Factor Verification Setup",
          requiresAuth: true,
          premium: true,
          permission: "SETTING_ACCOUNT",
        },
      },
      {
        path: "/custom-dashboard",
        name: "customDashboard",
        component: () =>
          import(
            /* webpackChunkName: "template" */ "../views/CustomDashboardPageView.vue"
          ),
        meta: {
          headerTitle: "Custom Dashboard",
          requiresAuth: true,
          premium: true,
          permission: "CUSTOM_DASHBOARD",
        },
      },
    ],
    meta: {
      requiresAuth: true,
    },
    redirect: "/dashboard",
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/reset",
    name: "reset",
    component: () =>
      import(
        /* webpackChunkName: "reset-main" */ "../views/ResetPasswordView.vue"
      ),
    children: [
      {
        path: "phone",
        name: "phone-input-page",
        component: () =>
          import(
            /* webpackChunkName: "reset-password-view-phone" */ "../views/ResetPasswordView.vue"
          ),
        meta: {
          headerTitle: "Phone Number Input",
          componentCode: "phone",
        },
      },
      {
        path: "otp-verification",
        name: "otp-verification-page",
        component: () =>
          import(
            /* webpackChunkName: "reset-password-view-otp" */ "../views/ResetPasswordView.vue"
          ),
        meta: {
          headerTitle: "OTP Verification",
          componentCode: "otp",
        },
      },
      {
        path: "change-password",
        name: "change-password-page",
        component: () =>
          import(
            /* webpackChunkName: "reset-password-view-password" */ "../views/ResetPasswordView.vue"
          ),
        meta: {
          headerTitle: "Change Password",
          componentCode: "password",
        },
      },
      {
        path: "successfully",
        name: "change-password-success-page",
        component: () =>
          import(
            /* webpackChunkName: "reset-password-view-success" */ "../views/ResetPasswordView.vue"
          ),
        meta: {
          headerTitle: "Change Password Successfully",
          componentCode: "success",
        },
      },
    ],
  },
  {
    path: "/sign-up",
    name: "sign-up",
    component: () =>
      import(/* webpackChunkName: "sign-up-main" */ "../views/SignUpView.vue"),
    children: [
      {
        path: "account",
        name: "account-info-page",
        component: () =>
          import(
            /* webpackChunkName: "sign-up-view-account" */ "../views/SignUpView.vue"
          ),
        meta: {
          headerTitle: "Account Information",
          componentCode: "account",
        },
      },
      {
        path: "otp",
        name: "account-otp-page",
        component: () =>
          import(
            /* webpackChunkName: "sign-up-view-otp" */ "../views/SignUpView.vue"
          ),
        meta: {
          headerTitle: "Account Verification",
          componentCode: "otp",
        },
      },
      {
        path: "store",
        name: "store-info-page",
        component: () =>
          import(
            /* webpackChunkName: "sign-up-view-store" */ "../views/SignUpView.vue"
          ),
        meta: {
          headerTitle: "Store Information",
          componentCode: "store",
        },
      },
      {
        path: "waba",
        name: "waba-info-page",
        component: () =>
          import(
            /* webpackChunkName: "sign-up-view-waba" */ "../views/SignUpView.vue"
          ),
        meta: {
          headerTitle: "Waba Information",
          componentCode: "waba",
        },
      },
      {
        path: "subscription",
        name: "subscription-info-page",
        component: () =>
          import(
            /* webpackChunkName: "sign-up-view-subscription" */ "../views/SignUpView.vue"
          ),
        meta: {
          headerTitle: "Subscription Information",
          componentCode: "subscription",
        },
      },
      {
        path: "success",
        name: "success-info-page",
        component: () =>
          import(
            /* webpackChunkName: "sign-up-view-success" */ "../views/SignUpView.vue"
          ),
        meta: {
          headerTitle: "Success",
          componentCode: "success",
        },
      },
    ],
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ForgotPasswordView.vue"),
    meta: {
      headerTitle: "Forgot Password",
      requiresAuth: false,
    },
  },
  {
    path: "/activate-mfa",
    name: "activate-mfa",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/ForceActivateMFAView.vue"
      ),
    meta: {
      headerTitle: "Force activate MFA",
      requiresAuth: false,
    },
  },
  {
    path: "/sign-up-shopify",
    name: "sign-up-shopify",
    component: () =>
      import(
        /* webpackChunkName: "sign-up-shopify" */ "../views/SignUpShopifyView.vue"
      ),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(
  async (to: Route, _from: Route, next: NavigationGuardNext) => {
    store.dispatch("setCommonLoading", true);
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      if (!store.getters.getIsLoggedIn) {
        next({
          name: "Login",
          query: to.query,
        });
      } else {
        const user = getUserDetailFromJwt(store.getters.getKokattoTokenAccess);
        const ability = new Ability(
          defineAbilityFor({
            role: store.getters.getAccountRole,
            accountCountry: store.getters.getAccountCountry,
            featuresAccessPermissions: user.featuresAccessPermission,
            featuresPermissions: user.featuresPermission,
          })
        );

        console.log("to.meta", to.meta);
        console.log("ability", ability);
        if (to.meta) {
          if (to.meta.permission) {
            if (!ability.can("READ", to.meta.permission)) {
              next({ path: brandConstants.DEFAULT_REDIRECT_PATH }); //ganti ke mana sesuai
              if (brandConstants.PARTNER_BRAND_NAME != "SmartChat") {
                store.dispatch("setSnackbarTop", {
                  color: "#FFD4D6",
                  isActive: true,
                  message: `${i18n.t("$vuetify.menuAccessForbiddenText")}`,
                });
              }
            }
          }
        }

        const clientId = store.getters.getClientId;
        const koalaPlusChatCreditServiceClient =
          new KoalaPlusChatCreditServiceClient({
            token: store.getters.getKoalaTokenAccess,
          });
        koalaPlusChatCreditServiceClient
          .getBalanceByClientId({
            clientId: clientId,
          })
          .then((res) => {
            console.log("getBalance", res);
            store.dispatch("setBalance", res.chatCredit.balance);
            store.dispatch("setBalanceBonus", res.chatCredit.balanceBonus);
          })
          .catch((err) => {
            console.log("err getBalance", err);
          });
        next();
      }
    } else {
      next();
    }
  }
);
router.afterEach(() => {
  store.dispatch("setCommonLoading", false); // End loading
});

export default router;
