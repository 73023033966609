// Request history tracker for monitoring API performance

import store from "@/store";

export interface RequestHistoryEntry {
  url: string;
  duration: number;
  timestamp: number;
  status: "success" | "error";
  statusCode?: number;
}

export interface RequestTrackerConfig {
  maxHistorySize?: number;
  logIntervalMs?: number;
  errorThresholdMs?: number;
}

class RequestTracker {
  private history: RequestHistoryEntry[] = [];
  private maxHistorySize = 100;
  private static instance: RequestTracker | null = null;
  private static printHistoryInterval: NodeJS.Timeout | null = null;
  private readonly intervalTime: number;
  private readonly errorThresholdMs: number;

  constructor(config?: RequestTrackerConfig) {
    this.maxHistorySize = config?.maxHistorySize ?? 100;
    this.intervalTime = config?.logIntervalMs ?? 1 * 60 * 1000; // 1 minutes default
    this.errorThresholdMs = config?.errorThresholdMs ?? 10000; // 10 seconds default

    // Set up a regular interval to print recent history
    if (RequestTracker.printHistoryInterval === null) {
      RequestTracker.printHistoryInterval = setInterval(() => {
        this.logRecentHistory();
      }, this.intervalTime);
    }
  }

  // Add a new entry to the request history
  addEntry(entry: RequestHistoryEntry): void {
    // Skip tracking requests with "tracker/received" or "identity/logs" path
    if (
      entry.url.includes("tracker/received") ||
      entry.url.includes("identity/logs")
    ) {
      return;
    }

    this.history.unshift(entry); // Add to beginning of array

    // Remove oldest entries if we exceed max size
    if (this.history.length > this.maxHistorySize) {
      this.history.pop();
    }
  }

  // Helper function to format URL paths
  private formatUrlPath(url: string): string {
    const urlParts = url.split("/");
    return urlParts.slice(-2).join("/");
  }

  // Helper function to format durations
  private formatDuration(ms: number): string {
    return ms > 1000 ? `${(ms / 1000).toFixed(2)}s` : `${ms.toFixed(0)}ms`;
  }

  // Print the request history entries from the last interval
  private logRecentHistory(): void {
    const now = Date.now();
    const recentEntries = this.history.filter(
      (entry) => now - entry.timestamp <= this.intervalTime
    );

    if (recentEntries.length === 0) {
      console.log(
        `No recent requests in the last ${this.intervalTime / 60000} minutes`
      );
      return;
    }

    // Calculate stats in a single pass through the array
    const stats = recentEntries.reduce(
      (acc, entry) => {
        acc.totalDuration += entry.duration;
        acc.successCount += entry.status === "success" ? 1 : 0;
        acc.errorCount += entry.status === "error" ? 1 : 0;

        if (entry.status === "error") {
          acc.errorRequests.push({
            path: this.formatUrlPath(entry.url),
            statusCode: entry.statusCode,
            duration: entry.duration,
          });
        }

        if (entry.duration > acc.maxDuration) {
          acc.maxDuration = entry.duration;
          acc.longestRequestPath = this.formatUrlPath(entry.url);
        }
        return acc;
      },
      {
        totalDuration: 0,
        successCount: 0,
        errorCount: 0,
        maxDuration: 0,
        longestRequestPath: "",
        errorRequests: [] as Array<{
          path: string;
          statusCode?: number;
          duration: number;
        }>,
      }
    );

    const avgDuration = stats.totalDuration / recentEntries.length;

    const logObject = {
      avgDuration: this.formatDuration(avgDuration),
      successCount: stats.successCount,
      errorCount: stats.errorCount,
      longestRequestPath: `${stats.longestRequestPath} (${this.formatDuration(
        stats.maxDuration
      )})`,
      errorRequests: stats.errorRequests.map(
        (e) =>
          `${e.path} (${e.statusCode}) - ${this.formatDuration(e.duration)}`
      ),
    };

    console.log(
      `Recent Request History:\n` +
        `- Average duration: ${logObject.avgDuration}\n` +
        `- Success count: ${stats.successCount}\n` +
        `- Error count: ${stats.errorCount}\n` +
        `- Longest request: ${logObject.longestRequestPath}\n` +
        `- Error requests: ${stats.errorRequests
          .map(
            (e) =>
              `${e.path} (${e.statusCode}) - ${this.formatDuration(e.duration)}`
          )
          .join(", ")}`
    );

    store.dispatch("submitLog", {
      payload: logObject,
      type: "NETWORK_LOG",
      isError: avgDuration > this.errorThresholdMs,
    });
  }

  // Get the full request history
  getHistory(): RequestHistoryEntry[] {
    return this.history;
  }

  // Clear the request history
  clearHistory(): void {
    this.history = [];
  }

  // Calculate average duration for a filtered subset of entries
  private calculateAverage(
    filterFn: (entry: RequestHistoryEntry) => boolean
  ): number {
    const filteredEntries = this.history.filter(filterFn);
    if (filteredEntries.length === 0) return 0;

    const total = filteredEntries.reduce(
      (sum, entry) => sum + entry.duration,
      0
    );
    return total / filteredEntries.length;
  }

  // Get average duration for all requests
  getAverageDuration(): number {
    return this.calculateAverage(() => true);
  }

  // Get average duration for successful requests
  getAverageSuccessDuration(): number {
    return this.calculateAverage((entry) => entry.status === "success");
  }

  // Get average duration for failed requests
  getAverageErrorDuration(): number {
    return this.calculateAverage((entry) => entry.status === "error");
  }

  // Cleanup resources
  destroy(): void {
    if (RequestTracker.printHistoryInterval) {
      clearInterval(RequestTracker.printHistoryInterval);
      RequestTracker.printHistoryInterval = null;
    }
  }

  // Create singleton instance with config
  static getInstance(config?: RequestTrackerConfig): RequestTracker {
    if (!RequestTracker.instance) {
      RequestTracker.instance = new RequestTracker(config);
    }
    return RequestTracker.instance;
  }
}

// Export a singleton instance
export const requestTracker = RequestTracker.getInstance();

// Export the class for custom instances
export default RequestTracker;
