import { IBrandConstants } from "@/models/IBrandConstants";
import { fa } from "vuetify/src/locale";
export const brandConstants: IBrandConstants = {
  PARTNER_CODE: "KOALA_PLUS",
  PARTNER_NAME: "Koala+",
  PARTNER_NAME_TEXT: "koalaplus",
  PARTNER_BRAND_NAME: "KOALA+",
  PARTNER_PREFIX_EMAIL: "kp-",
  DEFAULT_LANGUAGE: "id",

  PARTNER_BILLEDTYPE_CONFIGURABLE: true,
  PARTNER_BILLEDTYPE: "",
  PARTNER_PACKAGABLE: true,

  HAVE_FORGOT_PASSWORD: true,
  HANDLE_FORGOT_PASSWORD: true,
  FORGOT_PASSWORD_LINK: "",
  HAVE_REGISTER: true,
  HANDLE_REGISTER: true,
  REGISTER_LINK: "",
  PARTNER_BUSINESS_EMAIL: "business@koalaapp.id",
  PARTNER_CUSTOMER_SERVICE_EMAIL: "business@koalaapp.id",
  CREATE_USER_FORM_IS_SHOW_PASSWORD_INPUT_TEXT: true,
  CREATE_USER_FORM_IS_SHOW_EMAIL_INPUT_TEXT: true,
  CREATE_USER_FORM_IS_SHOW_EMAIL_LIST: false,
  CREATE_USER_FORM_IS_SHOW_EMAIL_LABEL: false,
  EDIT_USER_FORM_IS_ROLE_EDITABLE: false,
  CREATE_USER_FORM_IS_SHOW_GROUP_INPUT: false,
  IS_WABA_REQUIRED: true,
  IS_MULTIPLE_PACKAGE_SUBSCRIPTION: true,
  GET_MP_CHANNEL_ALL_SUBSCRIPTION: false,
  IS_BRAZE_ACTIVE: true,
  IS_FBM_ACTIVE: false,
  DEFAULT_REDIRECT_PATH: "/dashboard",
  MASK_PREMIUM_STATUS_AS: "",
  IS_STATUS_CHIP_OUTLINED: true,
  LOGIN_FORM_SUBMIT_ERROR_RESOURCE: "$vuetify.loginFormSubmitErrorAlt",
  CREATE_USER_FORM_ADD_USER_BANNER_RESOURCE: "$vuetify.settingAdminAddSuccess",
  CREATE_USER_FORM_USER_MAX_LIMIT_BANNER_RESOURCE:
    "$vuetify.settingAdminAddMax",
  CREATE_USER_FORM_CS_ABILITY_DESCRIPTION_RESOURCE:
    "$vuetify.settingAdminRoleCSInfo",
  SETTING_ADMIN_USER_ACCESS_LIMIT_RESOURCE: "$vuetify.settingAdminInfo",
  FREE_ACCOUNT_OVERLAY_RESOURCE: "$vuetify.freeAccountSubscribe",
  FREE_ACCOUNT_OVERLAY_ACTION_RESOURCE: "$vuetify.freeAccountSubscribeAction",
  BASE_URL_PRODUCTION: "https://plus.koalaapp.id",
  IS_STORE_INFO_HANDLED: true,
  ALLOWED_MEDIA_PLATFORM: [
    "WAP",
    "IGM",
    "MSG",
    "TPD",
    "LZD",
    "SHP",
    "LNE",
    "TLG",
  ],
  PREFILL_NAME_WITH_EMAIL: false,
  IS_AUTO_SUBCRIBE_AFTER_REGISTRATION: false,
  LOGIN_PAGE_ILLUSTRATION_HEADER_RESOURCE:
    "$vuetify.koalaPlusLoginIllustrationHeaderText",
  LOGIN_PAGE_ILLUSTRATION_SUBTITLE_RESOURCE:
    "$vuetify.koalaPlusLoginIllustrationSubtitleText",
  CUSTOM_WORDINGS: {
    EN: {
      botLoadingText: "Koala Assistant is typing...",
      botEmptyContent:
        "Write your message and Koala Assistant will help you finding idea",
      botTextFieldPlaceHolder: "Please tell Koala Assistant what do you need",
      formFollowUpNoTemplateContent:
        'Please contact admin to add new templates. If you need assistance, send a message via WhatsApp to KOALA Customer Care <a style="color:#1843A1; text-decoration:none;" href="https://wa.me/6287752670270" target="_blank">(087752670270)</a>.',
      formNewMessageNoTemplateContent:
        'Please contact admin to add new templates. If you have any problem related with this, please send a message to KOALA Customer Care via WhatsApp <a style="color:#1843A1; text-decoration:none;" href="https://wa.me/6287752670270" target="_blank">(087752670270)</a>.',
      coachmarkButtonAddAdminSetting: "Add user accounts based on each role.",
      broadcastDetailSentConfirmSubtitlePrepaid:
        "You will send broadcasts to <b>{0} contact(s)</b> via {1} for <b>{2} chat credits</b>.",
      broadcastScheduledDetailSentConfirmSubtitlePrepaid:
        "You will send broadcasts to <b>{0} contact(s)</b> via {1} on {2} at {3} for <b>{4} chat credits</b>.",
      templateContentInfoLinkVariableContact:
        "https://koalaapp.id/blog/430-tips-trik-122546-cara-untuk-menambahkan-data-variabel-kontak-dan-menambahkan-kontak-di-koala",
      emailCreatePreviewDomain: "@koalaapp.id",
    },
    ID: {
      botEmptyContent:
        "Tulis pesan dan Asisten Koala akan membantumu menemukan ide",
      botLoadingText: "Asisten Koala sedang mengetik...",
      botTextFieldPlaceHolder:
        "Infokan kepada Asisten Koala apa yang bisa dibantu",
      formFollowUpNoTemplateContent:
        'Silakan hubungi admin toko untuk menambahkan template. Jika kamu memiliki kendala, silakan kirim pesan WhatsApp ke KOALA Customer Care melalui no. <a style="color:#1843A1; text-decoration:none;" href="https://wa.me/6287752670270" target="_blank">087752670270</a>.',
      formNewMessageNoTemplateContent:
        'Silakan hubungi admin toko untuk menambahkan template pesan baru. Jika kamu memiliki kendala seputar ini, silakan kirim pesan WhatsApp ke KOALA Customer Care melalui no. <a style="color:#1843A1; text-decoration:none;" href="https://wa.me/6287752670270" target="_blank">087752670270</a>.',
      coachmarkButtonAddAdminSetting:
        "Tambah pengguna akun sesuai dengan peranan masing-masing.",
      broadcastDetailSentConfirmSubtitlePrepaid:
        "Kamu akan mengirim broadcast ke <b>{0} kontak</b> via {1}, dengan biaya sebesar <b>{2} chat credits</b>.",
      broadcastScheduledDetailSentConfirmSubtitlePrepaid:
        "Kamu akan mengirim broadcast ke <b>{0} kontak</b> via {1} pada tanggal {2} jam {3}, dengan biaya sebesar <b>{4} chat credits</b>.",
      templateContentInfoLinkVariableContact:
        "https://koalaapp.id/blog/430-tips-trik-122546-cara-untuk-menambahkan-data-variabel-kontak-dan-menambahkan-kontak-di-koala",
      emailCreatePreviewDomain: "@koalaapp.id",
    },
  },
  SHOW_CHATROOM_SEND_NEW_MESSAGE: true,
  LOGIN_AGREEMENT_LINK: "/pp.html",
  SHOW_SUPPORTED_BY: false,
  SHOW_NEW_BADGE_UNTIL: {
    menuContact: 1704041999999,
    listMenuCatalogWhatsapp: 1704041999999,
  },
  METABASE_QUESTION_IDS: {
    ID: {
      agentStatusQuestionId: "342",
      chatPerformanceQuestionId: "533",
      analysisQuestionId: "534",
      agentPerformanceQuestionId: "532",
      broadcastReportDashboardQuestionId: "535",
      broadcastReportHistoryQuestionId: "3122",
      broadcastReportHistoryDetailQuestionId: "3166",
      customerSatisfactionQuestionId: "391",
      broadcastHistoryChannelQuestionId: "2437",
      broadcastHistoryBroadcastOtpQuestionId: "2478",
      broadcastHistoryFollowUpQuestionId: "2479",
    },
    EN: {
      agentStatusQuestionId: "383",
      chatPerformanceQuestionId: "533",
      analysisQuestionId: "534",
      agentPerformanceQuestionId: "532",
      broadcastReportDashboardQuestionId: "535",
      broadcastReportHistoryQuestionId: "3122",
      broadcastReportHistoryDetailQuestionId: "3166",
      customerSatisfactionQuestionId: "390",
      broadcastHistoryChannelQuestionId: "2437",
      broadcastHistoryBroadcastOtpQuestionId: "2478",
      broadcastHistoryFollowUpQuestionId: "2479",
    },
  },
  CONNECT_TO_WHATSAPP_UNOFFICIAL: false,
  SHOW_CREDIT_BONUS_BANNER: true,
  SHOW_WEBSTORE_DASHBOARD: true,
  SHOW_OPENING_HOUR: true,
  SHOW_DELIVERY_SETTING: true,
  SHOW_BUSINESS_MODE_SETTING: true,
  SETTING_PAGE_PHONE_UPDATE_DESCRIPTION_RESOURCE:
    "$vuetify.storeSettingContactPhoneUpdate",
  SETTING_PAGE_WABA_UPDATE_DESCRIPTION_RESOURCE:
    "$vuetify.storeSettingContactWABAUpdate",
  SETTING_PAGE_EMAIL_UPDATE_DESCRIPTION_RESOURCE:
    "$vuetify.storeSettingContactEmailUpdate",
  PARTNER_ADMIN_LOCKABLE: false,
  SETTING_DASHBOARD_UPGRADE_TITLE_RESOURCE: "$vuetify.dashboardUpgradeTitle",
  SETTING_DASHBOARD_UPGRADE_DESC_RESOURCE:
    "$vuetify.dashboardUpgradeDescription",
  HIDE_DASHBOARD_FEATURE_BUTTON: {
    BROADCAST: false,
    CHATBOT: false,
    CS: false,
  },
  CONNECT_TO_SHOPEE: false,
  HAS_CONNECTED_CHANNEL_LIMITATION: false,
  CONNECT_TO_VIBER: false,
  SHOW_VARIABLE_LEARN_MORE_BUTTON: true,
  CONNECT_TO_LINE: true,
  CONNECT_TO_TELEGRAM: true,
  SHOW_REPORT_PAGE: true,
  SHOW_CUSTOMER_SATISFACTION_PERFORMANCE: true,
  SHOW_SURVEY_RATING: true,
  SHOW_OUTBOUND_PRICE_ALERT: true,
  API_BASE_URL: {
    production: "https://api.koalaapp.id/v1",
    development: "https://devapi.koalaapp.id/v1",
  },
  COACHMARK_SHOW_ON_TIER: "free trial",
  COACHMARK_REDIRECT_PATH: "/dashboard",
  SHOW_BROADCAST_REPORT: true,
  NEW_FEATURE_BANNER: true,
  CRM_CUSTOM_FIELD_GUIDELINE_URL:
    "https://koalaapp.id/blog/430-tips-trik-122546-cara-untuk-menambahkan-data-variabel-kontak-dan-menambahkan-kontak-di-koala",
  CONNECT_TO_LIVECHAT: false,
  CAN_SELECT_REGION: false,
  CUSTOMER_SERVICE_LINK: "http://wa.me/6281215000162",
  USER_CONSENT_AGREEMENT_LINK:
    "https://koala-asset.s3.ap-southeast-3.amazonaws.com/public/koala_plus_pt_kokatto_teknologi_global.html",
  USER_CONSENT_AGREEMENT_SHOW: true,
};
