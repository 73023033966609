import { RefreshableRequestHelper } from "@/helpers/RefreshableRequestHelper";
import {
  CsAgentGroup,
  CustomerServiceModel,
} from "@/models/CsAgentManagementModel";
import { KokattoTicketServiceClient } from "@/serviceClients/KokattoTicketServiceClient";
import { KokattoErrorResponse } from "@/serviceClients/responses/KokattoErrorResponse";
import { KokattoGetCsAgentGroupsResponse } from "@/serviceClients/responses/KokattoGetCsAgentGroupsResponse";
import { DEFAULT_BULK_UPDATE_STATE } from "@/components/customerService/dialog/BulkTicketUpdateDialogComponent/constants";
import { BulkUpdateState } from "@/components/customerService/dialog/BulkTicketUpdateDialogComponent/types";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import { KokattoGetCustomerServiceResponse } from "@/serviceClients/responses/KokattoGetCustomerServiceResponse";
import { KokattoCustomerServiceServiceClient } from "@/serviceClients/KokattoCustomerServiceServiceClient";
import { KokattoGetCustomerServiceRequest } from "@/serviceClients/requests/KokattoGetCustomerServiceRequest";

type State = {
  selectedChannelFilter: object | null;
  storeFilter: object | null;
  // chatIdDownloadedAttachments: Set<string>;
  chatIdDownloadedAttachments: string;
  transactionForm: object | null;
  customerPhoneNumber: string;
  csChatRole: string; // ChatRole: CS, CS-SPV, SPV
  csGroups: CsAgentGroup[];
  bulkUpdateState: BulkUpdateState;
  customerServiceList: CustomerServiceModel[] | null;
  isLoadingCustomerService: boolean;
};

const state: State = {
  selectedChannelFilter: null,
  storeFilter: null,
  // chatIdDownloadedAttachments: new Set(),
  chatIdDownloadedAttachments: "",
  transactionForm: null,
  customerPhoneNumber: "",
  csChatRole: "",
  csGroups: [],
  bulkUpdateState: { ...DEFAULT_BULK_UPDATE_STATE },
  customerServiceList: [],
  isLoadingCustomerService: false,
};

const getters: GetterTree<State, any> = {
  getSelectedChannelFilter: (state) => state.selectedChannelFilter,
  getStoreFilter: (state) => state.storeFilter,
  getDownloadedAttachments: (state) => state.chatIdDownloadedAttachments,
  getTransactionForm: (state) => state.transactionForm,
  getCustomerPhoneNumber: (state) => state.customerPhoneNumber,
  getCsChatRole: (state) => state.csChatRole,
  getCsGroups: (state) => state.csGroups,
  getBulkUpdateState: (state) => state.bulkUpdateState,
  getCustomerServiceList: (state) => state.customerServiceList,
  getIsLoadingCustomerService: (state) => state.isLoadingCustomerService,
};

const mutations: MutationTree<State> = {
  updateSelectedChannelFilter(state, channel: object) {
    state.selectedChannelFilter = channel;
  },
  updateStoreFilter(state, store: object) {
    state.storeFilter = store;
  },
  resetDownloadedAttachmentAfterRefresh(state, attachmentChatroomList: string) {
    state.chatIdDownloadedAttachments = attachmentChatroomList;
  },
  updateTransactionForm(state, transaction: object) {
    state.transactionForm = transaction;
  },
  updateCustomerPhoneNumber(state, customerPhoneNumber: string) {
    state.customerPhoneNumber = customerPhoneNumber;
  },
  updateDownloadedAttachments(state, payload: any) {
    const chatroomId = payload.chatroomId;
    const messageId = payload.messageId;
    const status = payload.status;
    const progress = payload.progress;
    let currentList: any;
    try {
      currentList = JSON.parse(state.chatIdDownloadedAttachments);
    } catch (err) {
      currentList = {};
    }
    const chatRoomDownloadedList: any[] = currentList[chatroomId] || [];
    const indexOfMessage = chatRoomDownloadedList.findIndex(
      (messageObj: any) => {
        return messageObj.messageId == messageId;
      }
    );
    if (indexOfMessage < 0) {
      const data: any = {
        messageId: messageId,
        status: 0,
        progress: 0,
      };
      if (status == "downloading") {
        data.status += 10;
      } else if (status == "downloaded") {
        data.status += 1;
        data.progress = 100;
      } else if (status == "progress") {
        data.progress = progress;
      }
      chatRoomDownloadedList.push(data);
    } else {
      const messageObj = chatRoomDownloadedList[indexOfMessage];
      if (status == "downloading") {
        messageObj.status += 10;
      } else if (status == "downloaded") {
        messageObj.status -= 10;
        if (messageObj.status % 10 == 0) {
          messageObj.status += 1;
        }
        messageObj.progress = 100;
      } else if (status == "progress") {
        messageObj.progress = progress;
      } else {
        messageObj.status -= 10;
      }
    }
    currentList[chatroomId] = chatRoomDownloadedList;
    const stringifyList: string = JSON.stringify(currentList);
    state.chatIdDownloadedAttachments = stringifyList;
  },
  updateCsChatRole(state, payload: string) {
    state.csChatRole = payload;
  },
  updateCsGroups(state, payload: CsAgentGroup[]) {
    state.csGroups = payload;
  },
  updateBulkUpdateState(state, payload: BulkUpdateState) {
    state.bulkUpdateState = { ...payload };
  },
  updateCustomerServiceList(state, payload: CustomerServiceModel[]) {
    state.customerServiceList = payload;
  },
  updateIsLoadingCustomerService(state, payload: boolean) {
    state.isLoadingCustomerService = payload;
  },
};

const actions: ActionTree<State, any> = {
  setSelectedChannelFilter(context, channel: object) {
    context.commit("updateSelectedChannelFilter", channel);
  },
  clearSelectedChannelFilter(context) {
    context.commit("updateSelectedChannelFilter", null);
  },
  setStoreFilter(context, store: object) {
    context.commit("updateStoreFilter", store);
  },
  clearStoreFilter(context) {
    context.commit("updateStoreFilter", null);
  },
  resetDownloadedAttachmentAfterRefresh(
    context,
    attachmentChatroomList: string
  ) {
    context.commit(
      "resetDownloadedAttachmentAfterRefresh",
      attachmentChatroomList
    );
  },
  updateDownloadedAttachments(context, payload: any) {
    context.commit("updateDownloadedAttachments", payload);
  },
  clearDownloadedAttachments(context) {
    context.commit("updateDownloadedAttachments", "");
  },
  setTransactionForm(context, transaction: object) {
    context.commit("updateTransactionForm", transaction);
  },
  clearTransactionForm(context) {
    context.commit("updateTransactionForm", null);
  },
  updateCustomerPhoneNumber(context, customerPhoneNumber: string) {
    context.commit("updateCustomerPhoneNumber", customerPhoneNumber);
  },
  clearCustomerPhoneNumber(context) {
    context.commit("updateCustomerPhoneNumber", "");
  },
  setCsChatRole(context, chatRole: string) {
    context.commit("updateCsChatRole", chatRole);
  },
  async getCsGroups(context): Promise<void> {
    const { commit } = context;
    const response: KokattoGetCsAgentGroupsResponse | KokattoErrorResponse =
      await RefreshableRequestHelper.requestKokatto<
        KokattoGetCsAgentGroupsResponse | KokattoErrorResponse
      >(() => {
        const kokattoTicketServiceClient = new KokattoTicketServiceClient();
        return kokattoTicketServiceClient.getCsAgentGroups({});
      });
    const { groups } = response as KokattoGetCsAgentGroupsResponse;
    if (groups) commit("updateCsGroups", groups);
  },
  /**
   * Fetches the customer service list from the Kokatto API and updates the store state.
   * This function dispatches a request to retrieve customer service data and commits
   * the results to the Vuex store. If an error occurs, it clears the customer service list.
   *
   * @param {Object} context - The Vuex store context object.
   * @returns {Promise<void>} - A promise that resolves once the customer service list is updated.
   */
  async fetchCustomerServiceList(context): Promise<void> {
    const { commit } = context;

    // Indicate that the customer service data is being loaded
    commit("updateIsLoadingCustomerService", true);

    // Request payload
    const request: KokattoGetCustomerServiceRequest = { fromForward: false };

    // Make an API request to fetch the customer service list
    const response: KokattoGetCustomerServiceResponse | KokattoErrorResponse =
      await RefreshableRequestHelper.requestKokatto<
        KokattoGetCustomerServiceResponse | KokattoErrorResponse
      >(() => {
        const kokattoCustomerServiceServiceClient =
          new KokattoCustomerServiceServiceClient();
        return kokattoCustomerServiceServiceClient.getCustomerService(request);
      });

    // Extract the customer service data from the response
    const { customerServices } = response as KokattoGetCustomerServiceResponse;

    // Update the store with the retrieved customer services or set it to null if none exist
    if (customerServices) commit("updateCustomerServiceList", customerServices);
    else commit("updateCustomerServiceList", null);

    // Mark loading as complete
    commit("updateIsLoadingCustomerService", false);
  },
};

const CustomerService = { state, getters, mutations, actions };

export default CustomerService;
