const id = {
  noBroadcastHistoryTitle: "Belum ada riwayat email",
  noBroadcastHistoryContent:
    "Info broadcast email yang kamu kirim akan muncul di halaman ini.",
  historyBroadcastCampaignCode: "Kode campaign",
  historyBroadcastTimeBroadcast: "Tanggal broadcast",
  historyBroadcastTemplateName: "Nama template",
  historyBroadcastBroadcastTotal: "Total penerima",
  historyBroadcastAccountSender: "Akun pengirim",
  historyBroadcastStatus: "Status",
  historyBroadcastAction: "Aksi",
  historyBroadcastStatusPartially: "Partially failed",
  historyBroadcastStatusDelivered: "Delivered",
  historyBroadcastStatusFailed: "Failed",
  historyBroadcastStatusInProcess: "In process",
  historyBroadcastErrorGeneral:
    "Gagal mendapatkan data broadcast. Silahkan coba beberapa saat lagi.",
  historyBroadcastViewDetail: "Lihat rincian",
  historyBroadcastEmailCC: "Penerima tambahan",
  historyBroadcastDate: "Tanggal broadcast",
  historyBroadcastCategory: "Kategori",
  historyBroadcastSubCategory: "Subkategori",
  historyBroadcastDetailSender: "Akun pengirim",
  historyBroadcastConnectedTitle: "Telepon tersambung",
  historyBroadcastRejectedTitle: "Telepon tidak tersambung",
  historyBroadcastReadTitle: "Total dibaca",
  historyBroadcastFailedTitle: "Gagal terkirim",
  historyBroadcastPreviousPageButton: "Kembali",
  historyBroadcastDetail: "Detail {0}",
  historyBroadcastDetailEmail: "Detail email",
  labelFilterEmailReceiver: "Cari email penerima",
  labelFilterByStatus: "Tampilkan status",
  historyBroadcastEmailReceiver: "Email penerima",
  historyBroadcastNumberReceiver: "Nomor penerima",
  historyBroadcastSendDate: "Tanggal dikirim",
  historyBroadcastReceivedDate: "Tanggal diterima",
  historyBroadcastReadDate: "Tanggal dibaca",
  historyBroadcastNotificationErrorMessage:
    "Terjadi masalah di email pelanggan: kotak masuk email penuh atau email tidak valid",
  historyBroadcastNotificationErrorVOIMessage:
    "Terjadi masalah di nomor pelanggan: panggilan tidak diangkat, sedang sibuk, atau tidak valid",
  historyBroadcastNotificationErrorSMSMessage:
    "Terjadi masalah di nomor pelanggan: nomor tidak aktif atau tidak valid",
  historyBroadcastFailedToSend: " {0} gagal dikirim ke penerima",
  labelFindReceiver: "Cari penerima",
  statusFilterSend: "Berhasil",
  statusFilterFailed: "Gagal",
  historyBroadcastNotFound: "Detail broadcast tidak ditemukan!",
  historyBroadcastErrorDownload:
    "Gagal download data broadcast. Silakan coba lagi.",
  historyBroadcastCheckpointEmpty: "Belum ada checkpoint untuk notifikasi.",
  historyBroadcastPreviewButton: "Preview {0}",
};

const en = {
  noBroadcastHistoryTitle: "No history yet",
  noBroadcastHistoryContent:
    "Email broadcast info that you've sent will appear here.",
  historyBroadcastCampaignCode: "Campaign code",
  historyBroadcastTimeBroadcast: "Broadcast Time",
  historyBroadcastTemplateName: "Template Name",
  historyBroadcastBroadcastTotal: "Total Recipients",
  historyBroadcastAccountSender: "Sender",
  historyBroadcastStatus: "Status",
  historyBroadcastAction: "Action",
  historyBroadcastStatusPartially: "Partially failed",
  historyBroadcastStatusDelivered: "Delivered",
  historyBroadcastStatusFailed: "Failed",
  historyBroadcastStatusInProcess: "In process",
  historyBroadcastErrorGeneral:
    "Failed get broadcast data. Please try again later.",
  historyBroadcastViewDetail: "View detail",
  historyBroadcastEmailCC: "Additional recipients",
  historyBroadcastDate: "Broadcast date",
  historyBroadcastCategory: "Category",
  historyBroadcastSubCategory: "Subcategory",
  historyBroadcastDetailSender: "Sender account",
  historyBroadcastConnectedTitle: "Voice connected",
  historyBroadcastRejectedTitle: "Voice rejected",
  historyBroadcastReadTitle: "Total read",
  historyBroadcastFailedTitle: "Failed to send",
  historyBroadcastPreviousPageButton: "Back",
  historyBroadcastDetail: "{0} detail",
  historyBroadcastDetailEmail: "Email detail",
  labelFilterEmailReceiver: "Find email receiver",
  labelFilterByStatus: "Show status",
  historyBroadcastEmailReceiver: "Email receiver",
  historyBroadcastNumberReceiver: "Receiver number",
  historyBroadcastSendDate: "Send date",
  historyBroadcastReceivedDate: "Received date",
  historyBroadcastReadDate: "Read date",
  historyBroadcastNotificationErrorMessage:
    "There was an issue with the customer's email: inbox is full or the email is invalid",
  historyBroadcastNotificationErrorVOIMessage:
    "There was an issue with the customer's number: maybe customer did not answer, busy, or number is invalid",
  historyBroadcastNotificationErrorSMSMessage:
    "There was an issue with the customer's number: maybe number is inactive or invalid",
  historyBroadcastFailedToSend: " {0} failed to be delivered to the recipients",
  labelFindReceiver: "Find receiver",
  statusFilterSend: "Completed",
  statusFilterFailed: "Failed",
  historyBroadcastNotFound: "Broadcast detail not found!",
  historyBroadcastErrorDownload:
    "Failed to download broadcast list, Please try again.",
  historyBroadcastCheckpointEmpty:
    "No checkpoint available for this notification.",
  historyBroadcastPreviewButton: "Preview {0}",
};

export default { id, en };
