import { ActionContext, ActionTree, GetterTree, MutationTree } from "vuex";
import {
  KoalaLoginResponse,
  KoalaTokenResponse,
  KokattoTokenResponse,
  ProductCatalogTokenResponse,
} from "@/serviceClients/responses/KoalaLoginResponse";
import router from "@/router";
import { RefreshableRequestHelper } from "@/helpers/RefreshableRequestHelper";
import { KoalaAccountServiceClient } from "@/serviceClients/KoalaAccountServiceClient";
import { KokattoAuthServiceClient } from "@/serviceClients/KokattoAuthServiceClient";
import { KoalaPlusAccountActivityRequest } from "@/serviceClients/requests/KoalaPlusAccountActivityRequest";
import { KoalaPlusAccountActivityServiceClient } from "@/serviceClients/KoalaPlusAccountActivityServiceClient";
import { getKoalaUserDetail } from "@/utils/jwtUtil";
import { KokattoGetCustomerServiceDetailResponse } from "@/serviceClients/responses/KokattoGetCustomerServiceDetailResponse";
import {
  isKokattoErrorResponse,
  KokattoErrorResponse,
} from "@/serviceClients/responses/KokattoErrorResponse";
import { KokattoCustomerServiceServiceClient } from "@/serviceClients/KokattoCustomerServiceServiceClient";
import { CentralizedIdentityServiceClient } from "@/serviceClients/CentralizedIdentityServiceClient";
import { KokattoLoggingServiceClient } from "@/serviceClients/KokattoLoggingServiceClient";
import { isError } from "cypress/types/lodash";

type AgentStatusState = {
  id: string;
  name: string;
  description?: string;
  icon?: string;
};

type State = {
  isLoggedIn: boolean;
  clientId: string;
  koalaToken: {
    accessToken: string;
    refreshToken: string;
  };
  productCatalogToken: {
    accessToken: string;
    accessTokenExptime: number;
    refreshToken: string;
    refreshTokenExptime: number;
  };
  kokattoToken: {
    token: string;
    tokenExpireAt: number;
    refreshToken: string;
    refreshTokenExpireAt: number;
    role: string;
    features: number;
    clientId: string;
    daysTillExpiration: string;
    featuresAccessName: string;
  };
  loginEmail: string;
  sharedLoginEmail: string;
  finishedTutorials: string[];
  subRole: string;
  agentStatus: AgentStatusState;
  lastAgentStatus: AgentStatusState;
  enableMultiClient: boolean;
};

const state: State = {
  isLoggedIn: false,
  clientId: "",
  koalaToken: {
    accessToken: "",
    refreshToken: "",
  },
  productCatalogToken: {
    accessToken: "",
    accessTokenExptime: 0,
    refreshToken: "",
    refreshTokenExptime: 0,
  },
  kokattoToken: {
    token: "",
    tokenExpireAt: 0,
    refreshToken: "",
    refreshTokenExpireAt: 0,
    role: "",
    features: 0,
    clientId: "",
    daysTillExpiration: "",
    featuresAccessName: "",
  },
  loginEmail: "",
  sharedLoginEmail: "",
  finishedTutorials: [],
  subRole: "",
  agentStatus: {
    id: "",
    name: "",
    description: "",
    icon: "",
  },
  lastAgentStatus: {
    id: "",
    name: "",
  },
  enableMultiClient: false,
};

const getters: GetterTree<State, any> = {
  getIsLoggedIn: (state) => state.isLoggedIn,
  getKoalaTokenAccess: (state) => state.koalaToken.accessToken,
  getKoalaTokenRefresh: (state) => state.koalaToken.refreshToken,
  getProductCatalogTokenAccess: (state) =>
    state.productCatalogToken.accessToken,
  getProductCatalogTokenRefresh: (state) =>
    state.productCatalogToken.refreshToken,
  getKokattoTokenAccess: (state) => state.kokattoToken.token,
  getKokattoTokenRefresh: (state) => state.kokattoToken.refreshToken,
  getClientId: (state) => state.clientId,
  // getAccountRole: (state) => state.kokattoToken.role,
  getLoginEmail: (state) => state.loginEmail,
  getSharedLoginEmail: (state) => state.sharedLoginEmail,
  getFinishedCoachmark: (state) => state.finishedTutorials,
  getAccountEmailFromAccount: (state, getters) => getters.getAccountEmail,
  getAccountSubRole: (state) => state.subRole,
  getKokattoFeatureAccessName: (state) => state.kokattoToken.featuresAccessName,
  getAccountRegion: (state) =>
    getKoalaUserDetail(state.koalaToken.accessToken).region || "",
  getAgentStatus: (state) => state.agentStatus,
  getLastAgentStatus: (state) => state.lastAgentStatus,
  getIsEnableMultiClient: (state) => state.enableMultiClient,
};

const mutations: MutationTree<State> = {
  updateIsLoggedIn(state, isLoggedIn: boolean) {
    state.isLoggedIn = isLoggedIn ?? false;
  },
  updateClientId(state, clientId: string) {
    state.clientId = clientId;
  },
  updateKoalaToken(state, response: KoalaTokenResponse) {
    state.koalaToken = response;
  },
  updateProductCatalogToken(state, response: ProductCatalogTokenResponse) {
    state.productCatalogToken = response;
  },
  updateKokattoToken(state, response: KokattoTokenResponse) {
    state.kokattoToken = response;
  },
  updateLoginEmail(state, email: string) {
    state.loginEmail = email;
  },
  updateSharedLoginEmail(state, email: string) {
    state.sharedLoginEmail = email;
  },
  updateFinishedCoachmark: (state, listFinishedCoachmark: string[]) => {
    state.finishedTutorials = listFinishedCoachmark;
  },
  updateSubRole(state, subRole: string) {
    state.subRole = subRole;
  },
  updateAgentStatus(state, agentStatus: AgentStatusState) {
    state.agentStatus = agentStatus;
  },
  updateLastAgentStatus(state, lastAgentStatus: AgentStatusState) {
    state.lastAgentStatus = lastAgentStatus;
  },
  updateMultiClient(state, enableMultiClient) {
    state.enableMultiClient = enableMultiClient;
  },
};

const actions: ActionTree<State, any> = {
  storeToken(context, koalaLoginResponse: KoalaLoginResponse) {
    const { koalaToken, productCatalogToken, kokattoToken, clientInfo } =
      koalaLoginResponse.data;
    context.commit("updateKoalaToken", koalaToken);
    context.commit("updateProductCatalogToken", productCatalogToken);
    context.commit("updateKokattoToken", kokattoToken);
    context.commit("updateIsLoggedIn", true);
    context.commit("updateMultiClient", clientInfo?.enableMultiClient ?? false);
  },
  storeClientId(context, koalaLoginResponse: KoalaLoginResponse) {
    const { clientId, role } = koalaLoginResponse.data;
    context.commit("updateClientId", clientId);
    context.commit("updateAccountRole", role);
  },
  // TODO: add send token to service worker
  storeTokenKokatto(context, koalaLoginResponse: KoalaLoginResponse) {
    const { kokattoToken } = koalaLoginResponse.data;
    context.commit("updateKokattoToken", kokattoToken);

    // updates service worker logging data
    navigator.serviceWorker.controller?.postMessage(
      JSON.stringify({
        shouldLog: process.env.VUE_APP_CLIENT_IDS_TO_LOG?.includes(
          context.getters.getClientId
        ),
        kokattoToken: context.getters.getKokattoTokenAccess,
        cisUrl:
          context.getters.getAccountRegion === "JKT"
            ? process.env.VUE_APP_BASEURL_CIS_AWS_JKT
            : process.env.VUE_APP_BASEURL_CIS_AWS,
      })
    );
  },
  storeTokenProductCatalog(context, koalaLoginResponse: KoalaLoginResponse) {
    const { productCatalogToken } = koalaLoginResponse.data;
    context.commit("updateProductCatalogToken", productCatalogToken);
  },
  storeTokenKoala(context, koalaLoginResponse: KoalaLoginResponse) {
    const { koalaToken } = koalaLoginResponse.data;
    context.commit("updateKoalaToken", koalaToken);
  },
  storeLoginEmail(context, email: string) {
    context.commit("updateLoginEmail", email);
    context.commit("updateSharedLoginEmail", email);
  },
  clearToken(context) {
    context.commit("updateFcmToken", "");
    context.commit("updateKoalaToken", "");
    context.commit("updateKokattoToken", "");
    context.commit("updateProductCatalogToken", "");
    context.commit("updateIsLoggedIn", false);
    context.commit("updateLoginEmail", "");
    context.commit("updateSharedLoginEmail", "");
    context.commit("updateClientId", "");

    navigator.serviceWorker.controller?.postMessage(
      JSON.stringify({
        kokattoToken: null,
        shouldLog: false,
      })
    );
  },
  setFinishedCoachmark(context, listFinishedCoachmark: string[]) {
    context.commit("updateFinishedCoachmark", listFinishedCoachmark);
  },
  clearStoreLogout({ dispatch, commit }) {
    dispatch("clearToken");
    dispatch("clearAccount");
    dispatch("clearConfiguration");
    dispatch("clearState");
    dispatch("clearMainOverlay");
    dispatch("clearAddress");
    dispatch("clearShowTemplateSubmiDisclaimer");
    dispatch("clearWSState");
    dispatch("clearDownloadedAttachments");
    dispatch("setAccountSubRole", "");
    dispatch("CustomerServicePlayMode/clearPlayModeData");
    commit("TicketChatListCustomHeader/resetStateToDefault");
    dispatch("setCsChatRole", "");
    dispatch("setAgentStatus", {
      id: "",
      name: "",
      description: "",
      icon: "",
    });
    commit("updateLastAgentStatus", {
      id: "",
      name: "",
      description: "",
      icon: "",
    });
  },
  async submitLog(context, { payload, isError, type = "AUTH_LOG" }) {
    const token = context.getters.getKokattoTokenAccess;
    if (!token) return;

    const serviceClient = new KokattoLoggingServiceClient({
      token: token,
    });

    try {
      const trackerData = {
        ...payload,
        createdAt: new Date().toISOString(),
      };

      const request = {
        event: JSON.stringify(trackerData),
        result: isError ? "error" : "success",
        type: type,
      };

      serviceClient.postLog(request);
    } catch (error) {
      console.error(error);
    }
  },
  async logoutAccount({ dispatch }) {
    await dispatch("revokeAccountKokatto");
    await dispatch("revokeAccountActivity");
    await dispatch("logoutAndRevokeTokenKokatto");
    dispatch("clearStoreLogout");
    router.push("/login");
  },
  async revokeAccountKokatto() {
    return RefreshableRequestHelper.requestKokatto<KoalaAccountServiceClient>(
      () => {
        const serviceClient = new KokattoAuthServiceClient();
        try {
          return serviceClient.updateStatusCSP();
        } catch (err) {
          console.log("Failed revoke account kokatto");
          return err as any;
        }
      }
    );
  },
  async revokeAccountActivity({ getters }) {
    const req: KoalaPlusAccountActivityRequest = {
      clientId: getters.getClientId,
      email: getters.getAccountEmailFromAccount,
    };
    return RefreshableRequestHelper.requestKoala<KoalaAccountServiceClient>(
      () => {
        // eslint-disable-next-line prefer-const
        let serviceClient = new KoalaPlusAccountActivityServiceClient();
        try {
          return serviceClient.deleteAccountActivity(req);
        } catch (err) {
          console.log("Failed revoke account activity");
          return err as any;
        }
      }
    );
  },
  /**
   * Will Post a logout request to CIS
   * Use to invalidate token & post blacklisted token to redis
   * @param param0
   * @returns
   */
  async logoutAndRevokeTokenKokatto({ getters }) {
    return RefreshableRequestHelper.requestKokatto<CentralizedIdentityServiceClient>(
      () => {
        const serviceClient = new CentralizedIdentityServiceClient();
        try {
          return serviceClient.logout(getters.getKokattoTokenAccess);
        } catch (err) {
          console.log("Failed logout account", err);
          return err as any;
        }
      }
    );
  },
  setAccountSubRole(context, subRole: string) {
    context.commit("updateSubRole", subRole);
  },
  // adding agent status detail to store
  setAgentStatus(context, agentStatus: AgentStatusState) {
    context.commit("updateAgentStatus", agentStatus);
  },
  async getCustomerServiceDetails(
    context: ActionContext<State, any>
  ): Promise<void> {
    const { commit, dispatch } = context;
    try {
      const response:
        | KokattoGetCustomerServiceDetailResponse
        | KokattoErrorResponse = await RefreshableRequestHelper.requestKokatto<KokattoGetCustomerServiceDetailResponse>(
        () => {
          const kokattoCustomerServiceServiceClient =
            new KokattoCustomerServiceServiceClient();

          return kokattoCustomerServiceServiceClient.getCustomerServiceDetails();
        }
      );
      dispatch("CustomerServicePlayMode/updateAgentPlayModeData", response);

      if (isKokattoErrorResponse(response)) {
        throw new Error(
          response?.error || "Failed to get customer service details"
        );
      }

      const { customerService } =
        response as KokattoGetCustomerServiceDetailResponse;

      // Default status if agent is first logs in
      const DEFAULT_STATUS = {
        id: "online",
        name: "ONLINE",
      };

      // Retrieve agent last status before last logout
      const {
        lastCsAvailabilityId = DEFAULT_STATUS.id,
        lastStatusDetail = DEFAULT_STATUS.name,
      } = customerService;

      // Save agent last status
      commit("updateLastAgentStatus", {
        id: lastCsAvailabilityId,
        name: lastStatusDetail,
      });

      // Retrieve agent current status
      const { csAvailabilityId, statusDetail, statusIcon } = customerService;
      // Save agent current status
      commit("updateAgentStatus", {
        id: csAvailabilityId,
        name: statusDetail,
        icon: statusIcon,
      });

      // set subrole
      if (customerService?.subRole) {
        commit("updateSubRole", customerService.subRole);
      }
    } catch (error) {
      console.log("Auth ~ getCustomerServiceDetails ~ error:", error);
      throw error;
    }
  },
};

const Auth = { state, getters, mutations, actions };

export default Auth;
